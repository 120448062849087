import React from 'react';
import styles from './CustomPartners.module.css';
import image1 from "../../utils/Assossiates/new_service_images/image1.png";
import image2 from "../../utils/Assossiates/new_service_images/image2.png";
import image3 from "../../utils/Assossiates/new_service_images/image3.jpg";
import image4 from "../../utils/Assossiates/new_service_images/image4.jpg";
import image5 from "../../utils/Assossiates/new_service_images/image5.jpg";
import image6 from "../../utils/Assossiates/new_service_images/image6.png";
import image7 from "../../utils/Assossiates/new_service_images/image7.png";
import image8 from "../../utils/Assossiates/new_service_images/image8.png";
import image9 from "../../utils/Assossiates/new_service_images/image9.png";
import image10 from "../../utils/Assossiates/new_service_images/image10.jpg";

import image11 from "../../utils/Assossiates/new_service_images/image11.jpg";
import image12 from "../../utils/Assossiates/new_service_images/image12.png";



const universityLogos = [
    { src: image1, alt: "Universiti Malaya" },
    { src: image2, alt: "Universitas Indonesia" },
    { src: image3, alt: "Sunway University" },
    { src: image4, alt: "UCSI University" },
    { src: image5, alt: "KLH University" },
    { src: image6, alt: "IIT Palakkad" },
    { src: image7, alt: "APU University" },
];

const softwareDevelopmentLogos = [
    { src: image8, alt: "ScalaCode" },
    { src: image9, alt: "76" },
    { src: image10, alt: "BenoSupport" },
];

const marketingLogos = [
    { src: image11, alt: "Flykez" },
    { src: image12, alt: "Hive" },
];

export default function CustomPartners() {
    const universityClass = universityLogos.length < 4 ? `${styles.logos} ${styles.centered}` : styles.logos;
    const softwareClass = softwareDevelopmentLogos.length < 4 ? `${styles.logos} ${styles.centered}` : styles.logos;
    const marketingClass = marketingLogos.length < 4 ? `${styles.logos} ${styles.centered}` : styles.logos;

    return (
        <div className={styles.partnersContainer}>
            <div className={styles.partnerSection}>
                <h3 className={styles.header}>Universities</h3>
                <div className={universityClass}>
                    {universityLogos.map((logo, index) => (
                        <img key={index} src={logo.src} alt={logo.alt} className={styles.partnerLogo} />
                    ))}
                </div>
            </div>

            <div className={styles.partnerSection} style={{ marginTop: '20px' }}>
                <h3 className={styles.header}>Software Developments</h3>
                <div className={softwareClass}>
                    {softwareDevelopmentLogos.map((logo, index) => (
                        <img key={index} src={logo.src} alt={logo.alt} className={styles.partnerLogo} />
                    ))}
                </div>
            </div>

            <div className={styles.partnerSection} style={{ marginTop: '20px' }}>
                <h3 className={styles.header}>Marketing</h3>
                <div className={marketingClass}>
                    {marketingLogos.map((logo, index) => (
                        <img key={index} src={logo.src} alt={logo.alt} className={styles.partnerLogo} />
                    ))}
                </div>
            </div>
        </div>
    );
}
